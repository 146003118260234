#benefit-details-container {
    width: 100%;
    background: #fafafa;
    .center {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
    }
    .script {
        display: block;
        position: relative;
        padding-left: 45px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 14px;
    }

    .container .padding-style {
        padding: 5% 12% 5% 12%;
    }
    .benefit-container {
        width: 100%;
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        .color-style {
            background-color: #24c4cc;
            width: 70%;
            height: 0.5em;
            border-radius: 8px 0px 0px 0px;
        }
        .next-style{
            background: #24C4CC;
            border-radius: 4px;
            color: #fafafa;
        }
        .benefit-table {
            table {
                border: none;
                width: 100%;
                border-collapse: collapse;
                margin-left: auto;
                margin-right: auto;

                tbody tr td {
                    padding: 10px 15px;
                    border: none;
                    font-size: 14px;
                    line-height: 24px;
                    color: #202020;
                    font-weight: 500;
                    text-align: left;
                }
                .benefit-list {
                    width: 80%;
                }
                .check {
                    width: 20%;
                }
            }
        }
    }

    /* Hide the default checkbox */
    // input[type=checkbox] {
    //     visibility: hidden;
    // }

    /* creating a custom checkbox based
        on demand */
    .geekmark {
        position: absolute;
        top: 0;
        right: 0;
        height: 25px;
        width: 25px;
        background-color: #24c4cc;
    }

    /* specify the background color to be
    shown when hovering over checkbox */
    .script:hover input ~ .geekmark {
        background-color: #24c4cc;
    }

    /* specify the background color to be
    shown when checkbox is active */
    .script input:active ~ .geekmark {
        background-color: #24c4cc;
    }

    /* specify the background color to be
    shown when checkbox is checked */
    .script input:checked ~ .geekmark {
        background-color: #24c4cc;
    }

    /* checkmark to be shown in checkbox */
    /* It is not be shown when not checked */
    .geekmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* display checkmark when checked */
    .script input:checked ~ .geekmark:after {
        display: block;
    }

    /* styling the checkmark using webkit */
    /* creating a square to be the sign of
        checkmark */
    .script .geekmark:after {
        left: 8px;
        bottom: 8px;
        width: 6px;
        height: 6px;
        border: 1px solid white;
        border-width: 4px 4px 4px 4px;
    }
}
