#product-selection-container {
    width: 100%;
    background: #fafafa;
    .h3{
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
    }
    .container .padding-style{
        padding: 5% 12% 5% 12%;
    }
    .product-container{
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        .color-style{
            background-color: #24C4CC;
            width: 10%;
            height: 0.500em;
            border-radius: 8px 0px 0px 0px;

        }
        .product{
            background: #24C4CC;
            width: 6.500em;
            height: 6.500em;
            border-radius: 3.350em;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            .center{
                margin-top: 30%;
                margin-left: 30%;
             }
            
        }
    }

}