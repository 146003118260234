#owner-details-container {
    width: 100%;
    background: #fafafa;
    .center {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    /* Seperator css */
    .separator {
        display: flex;
        align-items: center;
        text-align: center;
        color: #a0a0a0;
        font-size: 12px;
        margin-bottom: 30px;
    }

    .separator::before,
    .separator::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #dcdcdc;
        color: #dcdcdc;
    }

    .separator:not(:empty)::before {
        margin-right: 0.25em;
    }

    .separator:not(:empty)::after {
        margin-left: 0.25em;
    }
    /* Seperator css end */
    .h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
    }
    .container .padding-style {
        padding: 3% 5% 5% 5%;
    }
    .label {
        color: #202020;
    }
    .search-autocomplete {
        border: 0.5px solid #ccc;
        color: #1a1d1e;
        border-radius: 4px;
        max-height: 350px;
        overflow-y: auto;
        padding: 0;
        // box-shadow: 0 2px 10px rgb(0 31 69 / 40%);
        li {
            border-bottom: 0.5px solid #ccc;
            padding: 10px;
            display: block;
            color: #201a3d;
            cursor: pointer;
            /* height: 42px; */
        }
    }
    .list-unstyled {
        list-style: none;
        padding-left: 0;
    }
    .owner-container {
        width: 100%;
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        .color-style {
            background-color: #24c4cc;
            width: 30%;
            height: 0.5em;
            border-radius: 8px 0px 0px 0px;
        }
        .next-style {
            background: #24c4cc;
            border-radius: 4px;
            color: #fafafa;
        }
    }
}
