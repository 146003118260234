#confirmation-container {
    width: 100%;
}

#confirmation-container div.all {
    border: 1px solid gray;
    color: black;
    padding: 20px 30px;
    border-radius: 4px;

}

#confirmation-container div.head {
    padding: 10px;
    background-color: white;
}

#confirmation-container img{
    float:left;
}

#confirmation-container a {
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}

#confirmation-container .back {
    background-color: white;
    color: black;
    font-size: xx-large;
}

#confirmation-container .gear{
    float: right;
    font-size: x-large;
    background-color: #ffffff;
    color: #0000ff; 
}

#confirmation-container img {
    height: 100px;
}

#confirmation-container h2.heading {
    font-weight: 500;
    font-size: 24px;
    color: #202020;
}

#confirmation-container .policy-id {
    color: #A0A0A0;
    font-size: 14px;
}

#confirmation-container button {
    background-color: rgb(4, 187, 4);
    color: white;
    padding: 5px 25px;
    border-radius: 10px;
    font-size: 12px;
    border:rgb(4, 187, 4)
}

#confirmation-container button:hover {
    border: 2px solid rgb(4, 187, 4);
}

#confirmation-container img {
    float: left;
    margin-right: 20px;
    border-radius: 8px;
    width: 120px;
    height: 120px;
}

#confirmation-container table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#confirmation-container .policy-detail-table td {
    width: 30%;
}

#confirmation-container td, #confirmation-container th {
    border: 1px solid #ffffff;
    text-align: left;
    padding: 12px;
}

#confirmation-container tr:nth-child(even), #confirmation-container th {
    background-color: #FAFAFA;
}

#confirmation-container .benefits-table .center-header {
    text-align: center;
}

#confirmation-container .benefits-table .w-15 {
    width: 15%;
}

#confirmation-container .benefits-table .w-10 {
    width: 10%;
}

#confirmation-container .benefits-table .striked-content {
    text-decoration: line-through;
}

#confirmation-container .table-title {
    margin-top: 40px;
    font-weight: bold;
}

#confirmation-container div.last{
   padding: 20px;
   background-color: #ffffff;
   margin-top: 20px;
}   
 
#confirmation-container .next{
    color: #202020;
    width: 20%;
    font-size: 14px;
    font-weight: bold;
    background-color: #ffffff;
    text-align: left;
}

#confirmation-container table{
    background-color: white;
}

#confirmation-container .table-container {
    border: 1px solid #DCDCDC;
    margin-top: 20px;
}

#confirmation-container .member-file {
    color: #24C4CC;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
}

#confirmation-container .member-file > span{
    padding-right: 30px;
}