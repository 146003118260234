/* body {
    font-size: 18px;
    font-family: Calibri;
    
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    border: none;
    color:black;
} */

#view-manual-voucher-container {
  width: 100%;

  .m-ap-wrap {
    padding: 30px;
    background: #fafafa;
  }

  .pad-l-r-30 {
    padding: 30px 0;
  }

  .m-ap-wrap *,
  .m-ap-wrap *::after,
  .m-ap-wrap *::before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .m-ap-m-b-15 {
    margin-bottom: 15px;
  }

  .m-ap-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .m-ap-justify-content-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m-ap-justify-content-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .m-ap-align-items-center {
    -ms-flex-align: center;
    align-items: center;
  }

  .m-ap-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }

  .m-ap-row-table {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .m-ap-col-wrap {
    padding-right: 12px;
    padding-left: 12px;
  }

  .m-ap-input-f {
    margin-bottom: 15px;
  }

  .m-ap-label {
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4;
    color: #202020;
    display: block;
  }

  .m-ap-col-3 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }

  @media (min-width: 768px) {
    .m-ap-col-3 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 992px) {
    .m-ap-col-3 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }

  .m-ap-col-12 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }

  .m-ap-title {
    margin-bottom: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    color: #202020;
  }

  .m-ap-sub-title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: #202020;
  }

  .m-ap-border-top {
    border-top: 1px solid #dcdcdc;
  }

  .m-ap-btn {
    position: relative;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    display: inline-flex;
    padding: 9px 10px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .m-ap-btn.m-ap-h44 {
    padding: 11px 10px;
  }

  .m-ap-btn.plush {
    padding-left: 40px;
  }

  .m-ap-btn.plush::before,
  .m-ap-btn.plush::after {
    position: absolute;
    left: 10px;
    content: "";
    display: block;
    width: 15px;
    height: 1px;
    background-color: currentColor;
  }

  .m-ap-btn.plush::after {
    width: 1px;
    height: 15px;
    left: 17px;
  }

  .m-ap-btn.m-ap-blue {
    background: #24c4cc;
    border-color: #24c4cc;
    font-weight: bold;
    color: #ffffff;
  }

  .m-ap-action-btn {
    background: #ffffff;
    padding: 18px 15px;
  }

  .m-ap-action-btn .m-ap-small-btn {
    min-width: 120px;
    margin-right: 10px;
  }

  .m-ap-add-new {
    width: 79px;
    height: 24px;
    margin-right: 10px;
    // padding: 5px 10px;
    font-size: 12px;
  }

  .m-ap-input-f input[type="text"]::-webkit-input-placeholder,
  .m-ap-input-f input[type="text"]::-moz-placeholder,
  .m-ap-input-f input[type="text"]:-ms-input-placeholder,
  .m-ap-input-f input[type="text"]:-moz-placeholder,
  .m-ap-input-f input[type="email"]::-webkit-input-placeholder,
  .m-ap-input-f input[type="email"]::-moz-placeholder,
  .m-ap-input-f input[type="email"]:-ms-input-placeholder,
  .m-ap-input-f input[type="email"]:-moz-placeholder,
  .m-ap-input-f input[type="number"]::-webkit-input-placeholder,
  .m-ap-input-f input[type="number"]::-moz-placeholder,
  .m-ap-input-f input[type="number"]:-ms-input-placeholder,
  .m-ap-input-f input[type="number"]:-moz-placeholder {
    color: #a0a0a0;
  }

  .m-ap-input-f input[type="text"],
  .m-ap-input-f input[type="email"],
  .m-ap-input-f input[type="date"],
  .m-ap-input-f input[type="url"],
  .m-ap-input-f input[type="search"],
  .m-ap-input-f textarea,
  .m-ap-input-f select {
    padding: 9px 12px;
    height: 40px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    background: #ffffff;
    transition: all 0.3s ease-in-out;
  }

  .m-ap-input-f input[type="text"]:focus,
  .m-ap-input-f input[type="email"]:focus,
  .m-ap-input-f input[type="date"]:focus,
  .m-ap-input-f input[type="url"]:focus,
  .m-ap-input-f input[type="search"]:focus,
  .m-ap-input-f textarea:focus,
  .m-ap-input-f select:focus {
    border-color: #24c4cc;
    outline: 0;
  }

  .m-ap-input-f textarea {
    height: 40px;
    display: block;
  }

  .m-ap-input-f textarea #last {
    height: 202px;
  }

  .m-ap-search .m-ap-row {
    margin-left: -6px;
    margin-right: -6px;
  }

  .m-ap-search .m-ap-dropdown {
    width: 200px;
  }

  .m-ap-search .m-ap-dropdown select {
    font-size: 14px;
    font-weight: 700;
    height: 44px;
  }

  .m-ap-search .m-ap-input-f {
    padding-left: 6px;
    padding-right: 6px;
  }

  .m-ap-search .m-ap-search-f {
    width: calc(100% - 464px);
  }

  .m-ap-search .m-ap-search-f input[type="text"] {
    font-size: 18px;
    height: 44px;
  }

  .m-ap-search .m-ap-action .m-ap-btn {
    min-width: 120px;
  }

  .m-ap-search .m-ap-action .m-ap-btn:last-child {
    margin-left: 12px;
  }

  .m-ap-filter select {
    width: 160px;
  }

  .m-ap-filter .m-ap-filter-text {
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    .m-ap-filter .m-ap-title {
      margin-bottom: 0;
    }
  }

  #add-new {
    /* position: absolute; */
    width: 78px;
    height: 24px;
    /* left: 248px;
            top: 672px; */
    font-size: 12px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 24px;
    margin-top: 16px;
  }

  .amount-box {
    width: 452px;
    height: 92px;
    background: #fafafa;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 4px;
    float: right;
    margin-top: 16px;
    margin-right: 16px;
    margin-bottom: 16px;


    .amount-table {
      border-bottom: none;
      border-top: none;

      #th1 {
        width: 70%;
      }

      #tr1 {
        font-size: 21px;
      }

      td {
        width: 35%;
        border-top: none;
      }
    }
  }

  // .m-ap-table-box {
  //   background: #ffffff;
  //   border: 1px solid #dcdcdc;
  //   border-radius: 8px;
  //   overflow: hidden;
  //   margin-left: 630px;
  //   margin-right: 16px;
  //   margin-bottom: 16px;
  //   min-height: 92px;
  //   background: #FAFAFA;
  //   padding: 16px 20px;
  // }

  .m-ap-table,
  .m-ap-table table {
    width: 100%;
  }

  .m-ap-table table .m-ap-account-id {
    width: 14%;
  }

  .m-ap-table table .m-ap-sub-account {
    width: 14%;
  }

  .m-ap-table table .m-ap-transaction-type {
    width: 16%;
  }

  .m-ap-table table .m-ap-sub-type {
    width: 16%;
  }

  .m-ap-table table .m-ap-notes {
    width: 8%;
  }

  .m-ap-table table .m-ap-data {
    width: 8%;
  }

  .m-ap-table table .m-ap-debit {
    width: 9%;
  }

  .m-ap-table table .m-ap-credit {
    width: 9%;
  }

  .m-ap-table table .m-ap-action {
    width: 6%;
  }

  .m-ap-table table thead tr {
    background: #ffffff;
  }

  .m-ap-table table thead tr th {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 24px;
    color: #a0a0a0;
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
  }

  .m-ap-table table tbody tr td {
    padding: 10px 15px;
    border: none;
    font-size: 12px;
    line-height: 24px;
    color: #202020;
    font-weight: 500;
    text-align: left;
  }

  .m-ap-table table tbody tr td:first-child {
    text-decoration: underline;
  }

  .m-ap-table table tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .m-ap-table table tbody tr:nth-child(even) {
    background-color: #fafafa;
  }

  /* accordion */
  .m-ap-accordion {
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
  }

  .m-ap-accordion.m-ap-active .m-ap-ac-content {
    display: block;
  }

  .m-ap-accordion.m-ap-active .m-ap-arrow::before {
    top: 30px;
    transform: rotate(135deg);
  }

  .m-ap-accordion .m-ap-arrow {
    position: absolute;
    width: 80px;
    top: 0;
    bottom: 0;
    right: 0px;
    left: auto;
    z-index: 1;
  }

  .m-ap-accordion .m-ap-arrow::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-left: 1px solid #202020;
    border-bottom: 1px solid #202020;
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    margin: auto;
    transform: rotate(-45deg);
  }

  .m-ap-accordion .m-ap-ac-head {
    position: relative;
    padding: 20px 24px;
    cursor: pointer;
  }

  .m-ap-accordion .m-ap-ac-head .m-ap-sub-title {
    margin: 0;
    width: calc(100% - 80px);
  }

  .m-ap-accordion .m-ap-ac-content {
    display: none;
  }

  .m-ap-accordion .m-ap-form {
    padding: 0 24px 10px;
  }

}

.m-ap-bottom {
  padding: 18px;
  background-color: white;
  height: 80px;
  margin-top: 354px;

  #create-submit {
    background: #24c4cc;
    border-radius: 4px;
    width: 221px;
    height: 45px;
    font-size: 14px;
    color: white;
    border-color: #24c4cc;
    float: right;
    margin-left: 16px;
  }

  #approve-create {
    background: #ffffff;
    border: 1px solid #24c4cc;
    box-sizing: border-box;
    border-radius: 4px;
    width: 157px;
    height: 44.05px;
    float: right;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
  }

  #save-draft {
    background: #ffffff;
    border: 1px solid #24c4cc;
    box-sizing: border-box;
    border-radius: 4px;
    width: 157px;
    height: 44.05px;
    float: right;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
  }

  #cancel {
    background: #ffffff;
    width: 157px;
    height: 44.05px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    float: right;
  }
}

.modal1 {
  display: block;
  position: fixed;
  z-index: 3;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content1 {
    background-color: #fafafa;
    margin: auto;
    padding: 20px;
    border: 1px solid #fafafa;
    width: 30%;
    border-radius: 8px;

    .download-receipt-title1 {
      padding-left: unset;
      font-size: 34px;
      font-weight: 700;
      color: #202020;
    }

    .receipt-success {
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      background-color: #ffffff;

      .receipt-number {
        font-weight: bold;
      }

      .receipt-copy {
        color: #24c4cc;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .download-receipt-container {
      padding-right: unset;

      #download-receipt-close {
        float: right;
        width: 158px;
        height: 44px;
        margin-top: 6px;
        margin-right: 10px;
        margin-bottom: 20px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}

::placeholder {
  font-size: 14px;
}

.col-md-12,
.col-md-10 {
  margin-left: unset;
  margin-right: unset;
  padding-left: unset;
  padding-right: unset;
}

//  #create-multiple {
//     position: absolute;
//     width: 199px;
//     height: 40px;
//     left: 1225px;
//     top: 100px;
//     font-size: 14px;
//     background: #ffffff;
//     border: 1px solid #dcdcdc;
//     box-sizing: border-box;
//     border-radius: 4px;
// }


/* The Close Button */

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.anchor-links {
  cursor: pointer;
  padding: 5px;
}

.edit-icon {
  border-bottom: 1px solid #000000;
}

.action-row {
  background-color: #ffffff;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
}

.view-row td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.action-row td {
  padding: 1.3rem;
}

/* Loader Content */

.loader-content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}