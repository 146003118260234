/*
Font Usage:
- woff2: Super Modern Browsers
- woff: Pretty Modern Browsers
- ttf: Safari, Android, iOS
*/

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+HK:300,400,500,700&subset=chinese-hongkong&display=swap');

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 300;
  font-style: normal;
  src: url('./GT-Walsheim-Light.woff2') format('woff2'),
    url('./GT-Walsheim-Light.woff') format('woff'),
    url('./GT-Walsheim-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 300;
  font-style: oblique;
  src: url('./GT-Walsheim-Light-Oblique.woff2') format('woff2'),
    url('./GT-Walsheim-Light-Oblique.woff') format('woff'),
    url('./GT-Walsheim-Light-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 400;
  font-style: normal;
  src: url('./GT-Walsheim-Regular.woff2') format('woff2'),
    url('./GT-Walsheim-Regular.woff') format('woff'),
    url('./GT-Walsheim-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 400;
  font-style: oblique;
  src: url('./GT-Walsheim-Regular-Oblique.woff2') format('woff2'),
    url('./GT-Walsheim-Regular-Oblique.woff') format('woff'),
    url('./GT-Walsheim-Regular-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 500;
  font-style: normal;
  src: url('./GT-Walsheim-Medium.woff2') format('woff2'),
    url('./GT-Walsheim-Medium.woff') format('woff'),
    url('./GT-Walsheim-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 500;
  font-style: oblique;
  src: url('./GT-Walsheim-Medium-Oblique.woff2') format('woff2'),
    url('./GT-Walsheim-Medium-Oblique.woff') format('woff'),
    url('./GT-Walsheim-Medium-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 700;
  font-style: normal;
  src: url('./GT-Walsheim-Bold.woff2') format('woff2'),
    url('./GT-Walsheim-Bold.woff') format('woff'),
    url('./GT-Walsheim-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Walsheim';
  font-weight: 700;
  font-style: oblique;
  src: url('./GT-Walsheim-Bold-Oblique.woff2') format('woff2'),
    url('./GT-Walsheim-Bold-Oblique.woff') format('woff'),
    url('./GT-Walsheim-Bold-Oblique.ttf') format('truetype');
}
body {
  font-family: 'GT Walsheim';
}

