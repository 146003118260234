/* body {
    font-size: 18px;
    font-family: Calibri;
    
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    border: none;
    color:black;
} */
#view-receipt-container {
    width: 100%;
}

#view-receipt-container .header-container {
    padding: 20px 50px;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    height: 71px;
}

#view-receipt-container .header-title {
    font-size: 24px;
    color: #202020;
    font-weight: 700;
}

#view-receipt-container .content-container {
    background-color: #FAFAFA;
    padding: 20px;
    min-height: 700px;
}

#view-receipt-container .content-title {
    font-size: 18px;
    font-weight: bold;
    color: #202020;
}

#view-receipt-container .content-box {
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px 0;
    /* display: flex; */
    min-height: 160px;
    margin-bottom: 30px;
}
/* 
#view-receipt-container .content-box .title {
    font-size: 18px;
    font-weight: bold;
    color: #202020;
    padding-left: 15px;
} */

#view-receipt-container .content-box .form-text {
    border: 1px solid #DCDCDC;
}

#view-receipt-container .content-box .form-p {
    margin-top: 10px;
}

#view-receipt-container .receipt-details {
    padding: unset;
    width: 100%;
}

#view-receipt-container .receipt-details .title {
    padding: 20px;
}

#view-receipt-container .receipt-table-container {
    display: flex;
}

#view-receipt-container .receipt-table td,
#view-receipt-container .receipt-table th  {
    font-size: 12px;
    color: #202020;
}

#view-receipt-container .receipt-table th {
    font-weight: normal;
}

#view-receipt-container .receipt-table td {
    font-weight: 500;
}

#view-receipt-container .col-md-1, .col-md-10 {
    margin-left: unset;
    margin-right: unset;
    padding-left: unset;
    padding-right: unset;
}

#view-receipt-container .upload-csv-button {
    margin-top: -44px;
    font-size: 14px;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
}

#view-receipt-container .receipt-table-header {
    border: 1px solid #DCDCDC;
    border-radius: 4px;
}

#view-receipt-container  .bottom {
    padding: 30px;
    background-color: white;
    height: 100px;
}

#view-receipt-container  #continue {
    float: right;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 200px;
    height: 40px;
    color: #202020;
    border: 1px solid #24C4CC;
}

#view-receipt-container  #back-button {
    float: right;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    width: 150px;
    margin-top: 5px;
    margin-right: 10px;
}

#view-receipt-container #search_by {
    margin-top: 3px;
}

#view-receipt-container .or-container {
    text-align: center;
    padding-top: 40px;
    width: 30px;
}

#view-receipt-container .search-to {
    padding-top: 30px;
}


#view-receipt-container .primary-button {
    border: none;
    color: white;
    padding: 13px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    background-color: #24C4CC;
    border-radius: 6px;
    width: 150px;
    height: 45px;
    margin-right: 15px;
}

#view-receipt-container .receipt-title {
    font-size: 12px;
    font-weight: 300;
    color: #A0A0A0;
}

#view-receipt-container .receipt-number {
    font-weight: bold;
    padding-left: 5px;
}

#view-receipt-container .receipt-details-p {
    padding: 15px 30px;
    border-bottom: 1px solid #DCDCDC;
}

#view-receipt-container .receipt-details-p2 label {
    margin-bottom: unset;
}

#view-receipt-container .receipt-details-p2 {
    padding: 0 30px;
    border-bottom: 1px solid #DCDCDC;
}

#view-receipt-container .receipt-tab {
    padding: 15px;
    padding-top: 25px;
    font-size: 16px;
    color: #A0A0A0;
}

#view-receipt-container .tab-active {
    font-weight: bold;
    box-shadow: inset 0px -2px 0px #24C4CC;
    color: #202020;
}

#view-receipt-container .receipt-details-p3 {
    padding: 30px 0;
}

#view-receipt-container .receipt-details-line {
    padding: 20px;
}

#view-receipt-container .even {
    background-color: #F8F8F8;
}

#view-receipt-container .receipt-details-line .title{
    color: #727272;
    font-size: 14px;
    font-weight: 500;
}

#view-receipt-container .receipt-details-line .value{
    color: #202020;
    font-size: 14px;
    font-weight: 500;
}