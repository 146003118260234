#underwriting-container {
    width: 100%;
    background: #fafafa;
    .center {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
    }
    .next-style {
        background: #24c4cc;
        border-radius: 4px;
        color: #fafafa;
    }
    .container .padding-style {
        padding: 5% 12% 5% 12%;
    }
    .ans-btn {
        background: #ffffff;
        border: 1px solid #dcdcdc;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100px;
        height: 40px;
        text-align: center;
    }
    .element-active {
        border: solid 2px #24c4cc;
    }
    .benefit-container {
        width: 100%;
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        .color-style {
            background-color: #24c4cc;
            width: 80%;
            height: 0.5em;
            border-radius: 8px 0px 0px 0px;
        }
        .benefit-table {
            table {
                border: none;
                width: 100%;
                border-collapse: collapse;
                margin-left: auto;
                margin-right: auto;

                tbody tr td {
                    padding: 10px 15px;
                    border: none;
                    font-size: 14px;
                    line-height: 24px;
                    color: #202020;
                    font-weight: 500;
                    text-align: left;
                }
                .benefit-list {
                    width: 80%;
                }
                .check {
                    width: 20%;
                }
            }
        }
    }
}
