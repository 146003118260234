@import '~antd/dist/antd.css';
#uploadfile-container {
  width: 100%;
  background-color: #fafafa;

  .m-ap-wrap {
    padding: 30px;
  }
  .breadcrumbs ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .breadcrumbs ul li {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #a0a0a0;
    padding-right: 8px;
  }
  .breadcrumbs ul li a {
    color: #a0a0a0;
    text-decoration: none;
  }
  .breadcrumbs ul li a:hover {
    color: #24c4cc;
    text-decoration: none;
  }
  .breadcrumbs ul li.b-nolink {
    font-weight: bold;
  }

  .m-ap-top .breadcrumbs {
    width: calc(100% - 160px);
  }

  .m-ap-wrap *,
  .m-ap-wrap *::after,
  .m-ap-wrap *::before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .m-ap-m-b-15 {
    margin-bottom: 15px;
  }

  .m-ap-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .m-ap-justify-content-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m-ap-justify-content-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .m-ap-align-items-center {
    -ms-flex-align: center;
    align-items: center;
  }

  .m-ap-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }

  .m-ap-col-wrap {
    padding-right: 12px;
    padding-left: 12px;
  }
  .m-ap-addbox {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    overflow: hidden;
    margin-left:293px;
    margin-right: 87px;
    margin-bottom:47px;
    margin-top: 175px;
    height: 689px;
    width: 1092px;
    background-color: #ffffff;
    margin:36px 70px;
    .m-ap-insidebox {
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      overflow: hidden;
      margin: 16px;
      width: 1060px;
      height: 657px;
      background-color: #fafafa;
      .centered {
        text-align: center;
        margin-top: 250px;
        #line1 {
          font-size: 18px;
          font-weight: 500;
        }
        #line2 {
          font-size: 14px;
          font-weight: 400;
          color: #a0a0a0;
        }
      }
    }
  }
.modal-content1{
  width: 734px;
  height: auto;
  margin: 70px 300px 440px 406px;
  .centered1 {
    text-align: center;
    margin-top:40px;
    #line3 {
      font-size: 16px;
      font-weight: 500;
    }
    #or{
      font-weight:normal;
    }
    #line4 {
      font-size: 14px;
      font-weight:normal;
      color: #a0a0a0;
    }
  }
}
#drop{
  color: red;

}
#drop1{
  height: 33px;

}
#box1{
  text-align: center;
}
  .m-ap-col-3 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }

  .m-ap-title {
    margin-bottom: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    color: #202020;
  }

  .m-ap-border-top {
    border-top: 1px solid #dcdcdc;
  }
  .m-ap-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .m-ap-btn {
    position: relative;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    display: inline-flex;
    padding: 9px 10px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .m-ap-btn.m-ap-h44 {
    padding: 11px 10px;
  }

  .m-ap-btn.plush {
    padding-left: 40px;
  }

  .m-ap-btn.plush::before,
  .m-ap-btn.plush::after {
    position: absolute;
    left: 10px;
    content: "";
    display: block;
    width: 15px;
    height: 1px;
    background-color: currentColor;
  }

  .m-ap-btn.plush::after {
    width: 1px;
    height: 15px;
    left: 17px;
  }

  .m-ap-btn.m-ap-blue {
    background: #24c4cc;
    border-color: #24c4cc;
    font-weight: bold;
    color: #ffffff;
  }

  .m-ap-action-btn {
    background: #ffffff;
    padding: 18px 15px;
  }

  .m-ap-action-btn .m-ap-btn {
    min-width: 157px;
    margin-right: 10px;
  }

  .m-ap-input-f input[type="text"]::-webkit-input-placeholder,
  .m-ap-input-f input[type="text"]::-moz-placeholder,
  .m-ap-input-f input[type="text"]:-ms-input-placeholder,
  .m-ap-input-f input[type="text"]:-moz-placeholder,
  .m-ap-input-f input[type="email"]::-webkit-input-placeholder,
  .m-ap-input-f input[type="email"]::-moz-placeholder,
  .m-ap-input-f input[type="email"]:-ms-input-placeholder,
  .m-ap-input-f input[type="email"]:-moz-placeholder,
  .m-ap-input-f input[type="number"]::-webkit-input-placeholder,
  .m-ap-input-f input[type="number"]::-moz-placeholder,
  .m-ap-input-f input[type="number"]:-ms-input-placeholder,
  .m-ap-input-f input[type="number"]:-moz-placeholder {
    color: #a0a0a0;
  }

  .m-ap-input-f input[type="text"],
  .m-ap-input-f input[type="email"],
  .m-ap-input-f input[type="date"],
  .m-ap-input-f input[type="url"],
  .m-ap-input-f input[type="search"],
  .m-ap-input-f textarea,
  .m-ap-input-f select {
    padding: 9px 12px;
    height: 40px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    background: #ffffff;
  }

  .m-ap-input-f input[type="text"]:focus,
  .m-ap-input-f input[type="email"]:focus,
  .m-ap-input-f input[type="date"]:focus,
  .m-ap-input-f input[type="url"]:focus,
  .m-ap-input-f input[type="search"]:focus,
  .m-ap-input-f textarea:focus,
  .m-ap-input-f select:focus {
    border-color: #24c4cc;
    outline: 0;
  }

  .m-ap-search .m-ap-row {
    margin-left: -6px;
    margin-right: -6px;
  }

  .m-ap-search .m-ap-dropdown {
    width: 200px;
  }

  .m-ap-search .m-ap-dropdown select {
    font-size: 14px;
    font-weight: 700;
    height: 44px;
  }

  .m-ap-search .m-ap-input-f {
    padding-left: 6px;
    padding-right: 6px;
  }

  .m-ap-search .m-ap-search-f {
    width: calc(100% - 464px);
  }

  .m-ap-search .m-ap-search-f input[type="text"] {
    font-size: 18px;
    height: 44px;
  }

  .m-ap-search .m-ap-action .m-ap-btn {
    min-width: 120px;
  }

  .m-ap-search .m-ap-action .m-ap-btn:last-child {
    margin-left: 12px;
  }

  .m-ap-filter select {
    width: 160px;
  }

  .m-ap-filter .m-ap-filter-text {
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    .m-ap-filter .m-ap-title {
      margin-bottom: 0;
    }
  }

  .m-ap-table-box {
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    overflow: hidden;
  }

  .m-ap-table table {
    border: none;
    width: 100%;
    border-collapse: collapse;
  }

  .m-ap-table table .m-ap-account-id {
    width: 12%;
  }

  .m-ap-table table .m-ap-account-type {
    width: 18%;
  }

  .m-ap-table table .m-ap-account-name {
    width: 18%;
  }

  .m-ap-table table .m-ap-ledger-id {
    width: 12%;
  }

  .m-ap-table table .m-ap-active-from {
    width: 12%;
  }

  .m-ap-table table .m-ap-active-to {
    width: 10%;
  }

  .m-ap-table table .m-ap-status {
    width: 10%;
  }

  .m-ap-table table .m-ap-action {
    width: 8%;
  }

  .m-ap-table table thead tr {
    background: #ffffff;
  }

  .m-ap-table table thead tr th {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 24px;
    color: #a0a0a0;
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid #dcdcdc;
  }

  .m-ap-table table tbody tr td {
    padding: 10px 15px;
    border: none;
    font-size: 12px;
    line-height: 24px;
    color: #202020;
    font-weight: 500;
    text-align: left;
  }

  .m-ap-table table tbody tr td:first-child {
    text-decoration: underline;
  }

  .m-ap-table table tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .m-ap-table table tbody tr:nth-child(even) {
    background-color: #fafafa;
  }
  .popup-box .dismiss-box {
    float: right;
  }
  /* .popup-box .background-success {
      display: contents;
  } */

  .popup-box .row {
    position: fixed;
    width: 670px;
    height: 32px;
    left: 446px;
    top: 0px;
    color: white;
    background: #d8143f;
    border-radius: 0px 0px 4px 4px;
    padding: 5px;
  }
  .str-disable{
    pointer-events:none;
    cursor: no-drop;
}
}
#placehold{
  color:gray;
}
