#product-selection-container {
    width: 100%;
    background: #fafafa;
    .center {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    .h3{
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
    }
    .next-style{
        background: #24C4CC;
        border-radius: 4px;
        color: #fafafa;
        // padding: 12px 20px 12px 20px;
    }
    .container .padding-style{
        padding: 5% 12% 5% 12%;
    }
    .payment-container{
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        .color-style{
            background-color: #24C4CC;
            width: 100%;
            height: 0.500em;
            border-radius: 8px 0px 0px 0px;

        }
        .product{
            background: #24C4CC;
            width: 6.500em;
            height: 6.500em;
            border-radius: 3.350em;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            
        }
    }

}