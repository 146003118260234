#plan-details-container {
    width: 100%;
    background: #fafafa;
    .center {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    ul{
        padding-left: 10px;
    }
    li{
        color: #fafafa;
        font-size: 14px;
        font-weight: 400;
    }
    .container .padding-style{
        padding: 5% 12% 5% 12%;
    }
    .plan-container{
        width: 100%;
        background: #FFFFFF;
        border: 0.063em solid #DCDCDC;
        border-radius: 0.500em;
        .color-style{
            background-color: #24C4CC;
            width: 60%;
            height: 0.500em;
            border-radius: 0.500em 0px 0px 0px;

        }
        .plan-box{
            width: 15.750em;
            height: 21.125em;
            // width: -webkit-fill-available;
            // height: -webkit-fill-available;
            box-shadow: 0px 0.250em 0.500em rgba(0, 0, 0, 0.12);
            border-radius: 0.875em;
            cursor: pointer;
            // padding: 0.625em;
            // margin: 0.625em;

            .plan-name{
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                color: #FFFFFF;
            }
            .plan-code{
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                float: right;
                color: #FFFFFF;

            }
            .plan-info{
                width: fit-content;
                padding: 0.438em;
                color: #FFFFFF;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 0.750em;
            }
            .plan-benefits{
                height: 10.313em;
                overflow: hidden;
            }
            .read-more{
                color: #FFFFFF;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                float: right;
                cursor: pointer;
            }
            
        }
        // .plan-box:nth-child(even){
        //     background: #BDBDBD;

        // }
        .plan-box:nth-child(odd) {
            background: #24C4CC;                      
        }
        .plan-box:nth-child(even) {
            background: #9C43FF; 
        }

    }

}