#vehicle-details-container {
    width: 100%;
    background: #fafafa;
    .center {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    .h3{
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
    }
    .container .padding-style{
        padding: 3% 5% 5% 5%;
    }
    .label{
        color: #202020;
    }
    .vehicle-container{
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        .color-style{
            background-color: #24C4CC;
            width: 40%;
            height: 0.500em;
            border-radius: 8px 0px 0px 0px;

        }
        .next-style{
            background: #24C4CC;
            border-radius: 4px;
            color: #fafafa;
        }
        .upload-box{
            // width: 447px;
            // height: 325px;
            margin: auto;
            background: #FAFAFA;
            border: 1px solid #DCDCDC;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }

}