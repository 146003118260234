#checkout-container {
    width: 100%;
    background: #fafafa;
    .center {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
    }
    .next-style{
        background: #24C4CC;
        border-radius: 4px;
        color: #fafafa;
    }
    .container .padding-style {
        padding: 5% 12% 5% 12%;
    }
    .total-font{
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 24px;
    }
    .checkout-container {
        width: 100%;
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        .color-style {
            background-color: #24c4cc;
            width: 90%;
            height: 0.5em;
            border-radius: 8px 0px 0px 0px;
        }
        .payment-box{
            background: #FAFAFA;
            border: 1px solid #DCDCDC;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }
}
